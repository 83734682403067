@import url("https://fonts.googleapis.com/css?family=Space+Mono");
*, *:before, *:after {
  box-sizing: border-box; }

html, body {
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Space Mono', sans-serif;
}

.content__wrapper {
  position: relative;
  padding: 0 60px;
  width: 100%;
}

.about .header {
  position: relative;
  display: block;
  text-align: center;
}

.about .header h1 {
  font-size: 40px;
  margin: 10px 0;
}

.about .social__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.about .social__wrapper a {
  color: #3498db;
  padding: 0 20px;
}

.vertical__line {
  border-right: 1px solid #999;
  z-index: 1;
  position: absolute;
  top: 20px;
  left: 49px;
  bottom: 20px;
}

.main__container {
  border-left: 5px solid #3f87a6;
  position: relative;
  margin: 20px auto;
  padding: 0 20px;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;
  background: #eee;
  color: #696969;
}

.main__container code {
  font-size: 16px;
  margin: 0;
}

.main__container .span__text--blue {
  color: #07a;
}

.main__container .span__text--black {
  color: black;
}

.main__container .span__text--red {
  color: #DD4A68;
}

.main__container .span__text--green {
  color: #690;
}

.main__container a {
  text-decoration: none;
  color: #DD4A68;
  cursor: pointer;
}

.main__container ol {
  padding: 5px;
  counter-reset: item;
  list-style-type: none;
}

.main__container ol li {
  display: block;
  white-space: nowrap;
}

.main__container li:before {
  content: counter(item) "  ";
  counter-increment: item;
}

.tools {
  position: relative;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tools ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.tools li {
  padding: 5px 0;
}

.tools h1 {
  margin: 5px;
  font-family: Helvetica;
  font-size: 1.3em;
}

.tools span {
  padding: 5px;
}

.tools .javascript {
  background: linear-gradient(to bottom right, #f0db4f, transparent);
}

.tools .react {
  background: linear-gradient(to bottom right, #61dafb, transparent);
}

.tools .redux {
  background: linear-gradient(to bottom right, #764abc, transparent);
}

.tools .node {
  background: linear-gradient(to bottom right, #43853d, transparent);
}

.tools .spring-framework {
  background: linear-gradient(to bottom right, #90c53f, transparent);
}

.tools .html {
  background: linear-gradient(to bottom right, #f16529, transparent);
}

.tools .css {
  background: linear-gradient(to bottom right, #0277bd, transparent);
}

.tools .typescript {
  background: linear-gradient(to bottom right, #1e3799, transparent);
}

.tools .scala {
  background: linear-gradient(to bottom right, #cf649a, transparent);
}

.tools .akka {
  background: linear-gradient(to bottom right, #74b9ff, transparent);
}

.tools .postgresql {
  background: linear-gradient(to bottom right, #336791, transparent);
}

.tools .java {
  background: linear-gradient(to bottom right, #df3b31, transparent);
}

.tools .aws {
  background: linear-gradient(to bottom right, #f39c12, transparent);
}

.tools .kubernetes {
  background: linear-gradient(to bottom right, #3498db, transparent);
}

.tools .serverless {
  background: linear-gradient(to bottom right, #ff7675, transparent);
}

.tools .terraform {
  background: linear-gradient(to bottom right, #8e44ad, transparent);
}

.tools .serverless {
  background: linear-gradient(to bottom right, #df3b31, transparent);
}

@media screen and (max-width: 480px) {
  .content__wrapper {
    padding: 0 30px;
  }
  .main__container {
    padding: 0 5px;
  }
  .main__container code {
    font-size: 14px;
  }
  .main__container .vertical__line {
    left: 20px;
  }
  .main__container h3 {
    font-size: 1.3em;
  }
  .main__container h4, .main__container span {
    font-size: 14px;
  }
  .main__container ol {
      padding: 0;
  }
  .tools h1 {
    font-size: 1em;
  }
}